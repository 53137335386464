import React, { useState, useEffect } from 'react';
import './BlankPage.css';


function BlankPage() {
  
    const [theme, setTheme] = useState('dark');
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
  
    return (
      <div>
        <div class = "blank-page-body">
            <div class = "left-column">
                <div className="theme-toggle">
                <span
                    onClick={setDarkMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'dark' ? 'bold' : 'normal',
                    color: theme === 'dark' ? 'inherit' : '#929292',
                    }}
                >
                    ⏾ DARK
                </span>
                <span style={{ margin: '0 10px' }}> / </span>
                <span
                    onClick={setLightMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'light' ? 'bold' : 'normal',
                    color: theme === 'light' ? 'inherit' : '#929292',
                    }}
                >
                    ☼ LIGHT
                </span>
                </div>
            </div>
            <p class = "body-text">
            This page is under construction. Please check back for this later!
            </p>
        </div>
    </div>
    );

}

export default BlankPage;