import React, { useState } from 'react';
import './Table.css';

function Table() {
  const [isVisible, setIsVisible] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
    thirteen: false,
    fourteen: false,
    fifteen: false,
  });

  const toggleVisibility = (key) => {
    setIsVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="table">
      <div className="row-1">
        <div className="column-1"></div>
        <div className="column-2">Pros</div>
        <div className="column-3">Cons</div>
      </div>
      <div className="divider"></div>
      <div className="row-2">
        <div className="column-1">Reservation System (App)</div>
        <div className="column-2">
          <div className="table-element" onClick={() => toggleVisibility('one')}>
            <p>Guaranteed seating</p>
            <p className={`table-arrow ${isVisible.one ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.one ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Passengers could reserve a seat in advance, ensuring they don’t have to stand or compete for seats during their journey.</p>
          </div>
        </div>
        <div className="column-3">
          <div className="table-element" onClick={() => toggleVisibility('two')}>
            <p>Increased complexity</p>
            <p className={`table-arrow ${isVisible.two ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.two ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Managing a reservation system for short trips with frequent stops would be difficult. People board and leave the subway so frequently that enforcing reservations could cause delays and disputes.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('three')}>
            <p>Installation burden</p>
            <p className={`table-arrow ${isVisible.three ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.three ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Passengers have to download a new app, which might be burdensome and possibly lead to lower user adoption. </p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('four')}>
            <p>Privacy concerns</p>
            <p className={`table-arrow ${isVisible.four ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.four ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Some passengers might feel uncomfortable disclosing information about where they would get off.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('five')}>
            <p>Accessibility</p>
            <p className={`table-arrow ${isVisible.five ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.five ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Not everyone has access to or feels comfortable using mobile apps, particularly given the diverse demographic of subway riders.</p>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row-3">
        <div className="column-1">Real-time Availability Map</div>
        <div className="column-2">
          <div className="table-element" onClick={() => toggleVisibility('six')}>
            <p>Comprehensive view</p>
            <p className={`table-arrow ${isVisible.six ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.six ? 'visible' : ''}`}>
            <p class = "table-hidden-text">A live map of seat availability could be displayed on a screen in each car or through a mobile app, giving passengers a clear picture of where they should stand to find a seat.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('seven')}>
            <p>Low physical infrastructure</p>
            <p className={`table-arrow ${isVisible.seven ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.seven ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Compared to installing sensors for each seat, this solution would only require a centralized system that communicates seat occupancy data to a shared platform.</p>
          </div>
        </div>
        <div className="column-3">
          <div className="table-element" onClick={() => toggleVisibility('eight')}>
            <p>Passenger Interaction Required</p>
            <p className={`table-arrow ${isVisible.eight ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.eight ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Passengers would have to input when they are getting ready to leave through a voluntary system within an app / digital service, which might be burdensome and lead to lower user adoption.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('nine')}>
            <p>Privacy concerns</p>
            <p className={`table-arrow ${isVisible.nine ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.nine ? 'visible' : ''}`}>
           <p class = "table-hidden-text">Some passengers might feel uncomfortable disclosing information about where they would get off.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('ten')}>
            <p>Accessibility</p>
            <p className={`table-arrow ${isVisible.ten ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.ten ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Not everyone has access to or feels comfortable using mobile apps, particularly given the diverse demographic of subway riders.</p>
          </div>
        </div>
      </div>
      <div class = "divider"></div>
      <div className="row-4">
        <div className="column-1">Seat Sensor System</div>
        <div className="column-2">
          <div className="table-element" onClick={() => toggleVisibility('eleven')}>
            <p>Real-time feedback</p>
            <p className={`table-arrow ${isVisible.eleven ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.eleven ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Sensors could provide accurate, real-time information about seat availability in subway cabins, helping passengers locate vacant or soon-to-be-vacant seats quickly.</p>
          </div>
          <div class = "divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('twelve')}>
            <p>Reduced guesswork</p>
            <p className={`table-arrow ${isVisible.twelve ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.twelve ? 'visible' : ''}`}>
            <p class = "table-hidden-text">This would eliminate the need for passengers to rely on visual cues or luck, making the process more efficient.</p>
          </div>
          <div class = "divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('thirteen')}>
            <p>Ease of use</p>
            <p className={`table-arrow ${isVisible.thirteen ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.thirteen ? 'visible' : ''}`}>
            <p class = "table-hidden-text">The color-coded light system would be intuitive, requiring no prior knowledge or digital access, making it easy to understand at a glance.</p>
          </div>
        </div>
        <div className="column-3">
          <div className="table-element" onClick={() => toggleVisibility('fourteen')}>
            <p>High implementation costs</p>
            <p className={`table-arrow ${isVisible.fourteen ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.fourteen ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Installing sensors for every seat in every subway cabin could be expensive and complex.</p>
          </div>
          <div className="divider"></div>
          <div className="table-element" onClick={() => toggleVisibility('fifteen')}>
            <p>Uncertain user adoption</p>
            <p className={`table-arrow ${isVisible.fifteen ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.fifteen ? 'visible' : ''}`}>
            <p class = "table-hidden-text">Because signaling that they are vacating a seat soon is voluntary, passengers might forget or choose not to engage, which could reduce the system’s overall effectiveness in signaling upcoming seat availability.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
