import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import About from './About';
import Header from './Header';
import Footer from './Footer';
import FocusPlanet from './FocusPlanet';
import UHC from './UHC';
import Pivo from './Pivo';
import Greenify from './Greenify';
import GenSys from './GenSys';
import Subway from './Subway';
import BlankPage from './BlankPage';
import './App.css';

function App() {

  const [theme, setTheme] = useState('light'); // Manage theme state

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/focusplanet" element={<FocusPlanet />} />
            <Route path="/uhc" element={<BlankPage />} />
            <Route path="/pivo" element={<Pivo />} />
            <Route path="/greenify" element={<Greenify />} />
            <Route path="/gen-sys" element={<GenSys />} />
            <Route path="/subway" element={<Subway />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;