import React, { useEffect, useRef, useState } from 'react';
import './Function.css';
import Image1 from './assets/img/FocusPlanet/timer.png';
import Image2 from './assets/img/FocusPlanet/home.png';
import Image3 from './assets/img/FocusPlanet/planetpass.png';
import Image4 from './assets/img/FocusPlanet/reward.png';
import Image5 from './assets/img/FocusPlanet/crew.png';
import Image6 from './assets/img/FocusPlanet/report.png';

const Function = () => {
    const scrollSectionsRef = useRef([]);
    const [currentScreen, setCurrentScreen] = useState(Image1); // Default image

    useEffect(() => {
        const handleScroll = () => {
            scrollSectionsRef.current.forEach((section, index) => {
                const rect = section.getBoundingClientRect();

                // Check if the section is in the viewport and if it's the active section
                if (rect.top <= window.innerHeight / 2 && rect.bottom > window.innerHeight / 2) {
                    const screenSrc = section.getAttribute('data-screen');
                    console.log('Changing screen to:', screenSrc); // Log the image source
                    setCurrentScreen(screenSrc); // Update current screen
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const setRef = (el) => {
        if (el && !scrollSectionsRef.current.includes(el)) {
            scrollSectionsRef.current.push(el);
        }
    };

    return (
        <div class = "function-screen">
            <div class = "sticky-section">
                <div id = "app-screen">
                    <img src = {currentScreen} alt = "Focus Planet Screen" id = "screen-img" />
                </div>
            </div>
            <div class = "scroll-content">
                <section ref = {setRef} data-screen = {Image1}>
                    <p class = "section-subtitle" id = "function-mobile-title">Study Timer</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                </section>
                {/*<section ref = {setRef} data-screen = {Image1}>
                    <p class = "body-text" id = "test-text">Users earn <span class = "white-text">1 star per minute</span> when they track their focus time with Focus Planet.</p>
                </section>*/}
                <section ref = {setRef} data-screen = {Image2}>
                    <p class = "section-subtitle" id = "function-mobile-title">Home</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">On the home screen, users can add and manage tasks. When they achieve their daily study goals, the moon fills up!</p>
                </section>
                <section ref = {setRef} data-screen = {Image3}>
                    <p class = "section-subtitle" id = "function-mobile-title">Planet Pass</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">After 1 hour of continuous focus, users get a chance to enter a random bonus reward draw.</p>
                </section>
                <section ref = {setRef} data-screen = {Image4}>
                    <p class = "section-subtitle" id = "function-mobile-title">Shop</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">Points can be used in the reward shop to purchase gift cards, just like cash.</p>
                </section>
                <section ref = {setRef} data-screen = {Image5}>
                    <p class = "section-subtitle" id = "function-mobile-title">Crew</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">Users compete and achieve goals together with friends in study crews.</p>
                </section>
                <section ref = {setRef} data-screen = {Image6}>
                    <p class = "section-subtitle" id = "function-mobile-title">Stats</p>
                    <div class = "divider"></div>
                    <p class = "body-text" id = "function-mobile-subtext">In the Stats tab, users can review their daily, weekly, and monthly study patterns.</p>
                </section>
                {/* Add more sections as needed */}
            </div>
        </div>
    );
};

export default Function;
