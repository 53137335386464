import React from 'react';
import './Footer.css';

function Footer() {

  return (
    <footer>
      <div class = "left-section">
        <p></p>
      </div>
      <div class = "right-section">
        <p><a class = "footer-link" href="mailto:claire6001@hotmail.com">email ↗</a></p>
        <p><a class = "footer-link" href="http://linkedin.com/in/claire-cheong-26631819a" target="_blank" rel="noopener noreferrer">linkedin ↗</a></p>
      </div>
    </footer>
  );
}

export default Footer;