import React, { useState } from 'react';
import './ResultBox.css';
import testResult1Light from './assets/img/Subway/test-result-1-light.png';
import testResult2Light from './assets/img/Subway/test-result-2-light.png';
import testResult3Light from './assets/img/Subway/test-result-3-light.png';
import testResult1Dark from './assets/img/Subway/test-result-1-dark.png';
import testResult2Dark from './assets/img/Subway/test-result-2-dark.png';
import testResult3Dark from './assets/img/Subway/test-result-3-dark.png';

function ResultBox({ theme }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of images and descriptions for light and dark modes
  const results = [
    {
      light: testResult1Light,
      dark: testResult1Dark,
      description: {
        tag: 'FINDING 1',
        line1: 'Participants relied on the newly implemented features - foot button and LED lights - to signal departure and find seats within the cabin',
        line2: 'Excluding 6 participants who hadn’t occupied a seat, 50% (6 out of 12) pressed the foot button, and 67% (12 out of 18) used light colors to locate seats.',
      },
    },
    {
      light: testResult2Light,
      dark: testResult2Dark,
      description: {
        tag: 'FINDING 2',
        line1: 'There was a 72.71% decrease in perceived inconvenience of finding seats following the user test',
        line2: 'While external factors like the congestion rate may have influenced this change, the results suggest some efficacy in the design solution.',
      },
    },
    {
      light: testResult3Light,
      dark: testResult3Dark,
      description: {
        tag: 'FINDING 3',
        line1: 'Participants expressed a relatively high willingness to engage with the technology if implemented in a real-world setting',
        line2: 'When asked to rate a scale of 0-10, the average score was 7.56, indicating positive receptivity toward the new features.',
      },
    },
  ];

  // Function to handle previous button
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? results.length - 1 : prevIndex - 1
    );
  };

  // Function to handle next button
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === results.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="result-box">
      <div className="result-box-left-section">
        <div className="description">
            <div className="content-tag" id = "result-tag">{results[currentIndex].description.tag}</div>
          <div className="italic-text" id = "result-italic-text">{results[currentIndex].description.line1}</div>
          <div class = "result-body-text">{results[currentIndex].description.line2}</div>
        </div>
      </div>
      <div className="result-box-right-section">
        <div className="result-img-wrapper">
          <button onClick={goToPrevious} className="result-arrow-button">
            {'〈'}
          </button>
          <img
            src={theme === 'light' ? results[currentIndex].light : results[currentIndex].dark}
            alt="test result"
          />
          <button onClick={goToNext} className="result-arrow-button">
            {'〉'}
          </button>
        </div>
        <div className="dots-container">
          {results.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResultBox;
