import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './GenSys.css';
import headerImg from './assets/img/GenSys/gen-sys-header-img.png';
import animateImg from './assets/img/GenSys/animation.png';
import audioReactiveImg from './assets/img/GenSys/audio-reactive.png';
import surveyImgDarkMode from './assets/img/GenSys/survey-img-dark-mode.png';
import surveyImgLightMode from './assets/img/GenSys/survey-img-light-mode.png';
import genSysVideo1 from './assets/video/gen-sys-video2.mp4';
import problemImg1 from './assets/img/GenSys/problem-img1.png';
import problemImg2 from './assets/img/GenSys/problem-img2.png';
import problemImg3 from './assets/img/GenSys/problem-img3.png';
import video1 from './assets/video/Sequence01.mov';
import video2 from './assets/video/Sequence02.mp4';
import video3 from './assets/video/Sequence03.mp4';
import video4 from './assets/video/Sequence04.mp4';

function GenSys() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#project-summary', '#overview', '#problem', '#method', '#result'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = {`left-column-list-text ${activeSection === '#project-summary' ? 'active' : ''}`}>
                            <Link to="/gen-sys#project-summary" duration={500}>project summary</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#overview' ? 'active' : ''}`}>
                            <Link to="/gen-sys#overview" duration={500}>overview</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#problem' ? 'active' : ''}`}>
                            <Link to="/gen-sys#problem" duration={500}>problem</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#method' ? 'active' : ''}`}>
                            <Link to="/gen-sys#method" duration={500}>method</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#result' ? 'active' : ''}`}>
                            <Link to="/gen-sys#result" duration={500}>result</Link>
                        </li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "left-column-list-text" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <p class = "project-subtitle" id = "project-summary">Generative Systems for Design</p>
                    <h1 class = "project-title">Animating Static Album Covers to Bring Music to the Deaf</h1>
                    <div class = "header-img">
                        <img src = {headerImg} alt = "Greenify Header Image" />
                    </div>
                    <p class = "project-description">For my final project in Generative Systems for Design, I focused on creating a unique experience that visualizes music for the deaf. To achieve this, I animated static album covers using generative algorithms, utilizing TouchDesigner as my primary tool. Through the interplay of color, motion, and form, I sought to convey the emotions and rhythms of the music, providing a rich visual experience that resonates beyond sound.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">5 Weeks <br></br>(Spring 2024)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Designer, Engineer </p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Solo Project <br></br>Advised by Jingyang Liu</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma<br></br>TouchDesigner</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3">
                        </div>
                    </div>
                    <section class = "content-section" id = "overview">
                        <div class = "section-content">
                            <p class = "content-tag">01 OVERVIEW</p>
                            <p class = "section-subtitle">How might we visualize music for the deaf?</p>
                            <p class = "body-text" id = "solution-text">Inspired by the introduction of animated album covers on platforms like Apple Music and Spotify, I used generative methods to create animations that capture the beat, tempo, and lyrical sentiment of the song. The following prototype is a demonstration of how this could be integrated into existing music apps. </p>
                            <video id = "gen-sys-video1" autoPlay loop playsInline controls>
                                <source src= {genSysVideo1} type="video/mp4" />
                            </video>
                        </div>
                    </section>
                    <section class = "content-section" id = "problem">
                        <p class = "content-tag">02 PROBLEM</p>
                        <div class = "section-content">
                            <p class = "body-text">For many people, accessing and listening to music is a very simple and easy process. However, music is not something that is fully accessible to people who are deaf. Some barriers to perceiving music include: </p>
                            <div id = "problem-wrapper">
                                <div id = "problem-item">
                                    <img class = "problem-img" src={problemImg1} />
                                    <p class = "body-text" id = "problem-text">Challenges of feeling bass vibrations from loud music in public</p>
                                </div>
                                <div id = "problem-item">
                                    <img class = "problem-img" src={problemImg2} />
                                    <p class = "body-text" id = "problem-text">Insufficient vibration output in mobile devices</p>
                                </div>
                                <div id = "problem-item">
                                    <img class = "problem-img" src={problemImg3} />
                                    <p class = "body-text" id = "problem-text">Limited accommodation of sign language interpretation for lyrics</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "method">
                        <p class = "content-tag">03 METHOD</p>
                        <div class = "section-content">
                            <div class = "workflow-img">
                                    <div class = "workflow-img1">
                                        <p class = "similarity-text" id = "similarity-title">Animation</p>
                                        <p class = "similarity-text">1. Take in album cover as image input</p>
                                        <p class = "similarity-text">2. Animate the image using feedback loop and noise</p>
                                    </div>
                                    <div class = "arrow">·········▸</div>
                                    <div class = "workflow-img2">
                                        <p class = "similarity-text" id = "similarity-title">Audio-reactive visuals</p>
                                        <p class = "similarity-text">1. Take in song as audio input</p>
                                        <p class = "similarity-text">2. Analyze audio</p>
                                        <p class = "similarity-text">3. Link audio elements to noise used to animate the static image</p>
                                    </div>
                                    <div class = "arrow">·········▸</div>
                                    <div class = "workflow-img3">
                                        <p class = "similarity-text" id = "similarity-title">Emotion Mapping</p>
                                        <p class = "similarity-text">1. Extract key emotion from lyrics</p>
                                        <p class = "similarity-text">2. Map emotions to colors</p>
                                        <p class = "similarity-text">3. Apply color to animated image</p>
                                    </div>
                            </div>
                            <p class = "body-text">Following the workflow, the first step that I took was taking in the image of the album cover as input using Movie File In in TouchDesigner and animating the image using Feedback loop and Noise.</p>
                            <img class = "method-img" src = {animateImg} alt = "TouchDesigner Network for animating static images using Particles GPU" />
                            <p class = "image-subtext">TouchDesigner Network for animating static images using Particles GPU</p>
                            <p class = "body-text">Then, in order to make the image audio-reactive, I took the corresponding song as audio input using Audio File In, combined the two channels, and analyzed the audio using Analyze. Referencing the beat of the music with Math, Lag and Speed, I connected the final Null to the Noise that is used to animate the image.</p>
                            <img class = "method-img" src = {audioReactiveImg} alt = "TouchDesigner Network for making audio-reactive visuals" />
                            <p class = "image-subtext">TouchDesigner Network for making audio-reactive visuals</p>
                            <p class = "body-text">The next step was to conduct the emotion analysis of the lyrical content of the song. Since I had decided to convey emotions through color, my initial task was to associate each emotion with a corresponding color. I created a Google Forms survey asking people to associate seven emotions (anger, disgust, fear, joy, neutral, sadness, and surprise) each with seven colors (red, orange, yellow, green, blue, purple, and pink).</p>
                            <img class = "method-img" src={theme === 'light' ? surveyImgLightMode : surveyImgDarkMode} alt = "Emotion mapping Survey Results" />
                            <p class = "body-text">I received a total of 42 responses and the result was as above. Most people associated anger with red, disgust with green, joy with yellow, sadness with blue, surprise with pink, neutral with purple, and fear with orange.</p>
                            <p class = "body-text">In order to extract key emotion from lyrics, I used an external pipeline named text2emotion to analyze the emotion from the lyrical content. </p>
                            <p class = "body-text">Lastly, I used the result of the emotion mapping to transformed the color of the visual according to the output of the Python code. </p>
                        </div>
                    </section>
                    <section class = "content-section" id = "result">
                        <p class = "content-tag">03 VARIATIONS</p>
                        <div class = "section-content">
                        <p class = "body-text">I made four variations of different songs and album covers, using the same procedure to show how this generative method could be used across different pieces of music.</p>
                            <div id = "variation-wrapper">
                                <div class = "variation-item">
                                    <video class = "variation-video" controls>
                                        <source src= {video1} type="video/mp4" />
                                    </video>
                                    <p class = "song-title">The Less I Know The Better</p>
                                    <p class = "song-artist">Tame Impala</p>
                                </div>
                                <div class = "variation-item">
                                    <video class = "variation-video" controls>
                                        <source src= {video2} type="video/mp4" />
                                    </video>
                                    <p class = "song-title">Got My Number</p>
                                    <p class = "song-artist">MONSTA X</p>
                                </div>
                                <div class = "variation-item">
                                    <video class = "variation-video" controls>
                                        <source src= {video3} type="video/mp4" />
                                    </video>
                                    <p class = "song-title">Maverick</p>
                                    <p class = "song-artist">THE BOYZ</p>
                                </div>
                                <div class = "variation-item">
                                    <video class = "variation-video" controls>
                                        <source src= {video4} type="video/mp4" />
                                    </video>
                                    <p class = "song-title">everything i wanted</p>
                                    <p class = "song-artist">Billie Eilish</p>
                                </div>
                            </div>
                            {/*<p class = "body-text">The last piece of this project was to imagine how this function would be implemented in existing music platforms so that it would be easily accessible. I created a prototype of how this function will be incorporated into a music app, in this case Spotify, using Figma.</p>*/}
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                    <p class = "project-footer-subtitle">3i Inc.</p>
                        <p class = "project-footer-title">Designing at the Intersection of Hardware and Software</p>
                        <button class = "read-more-button" onClick={() => navigate('/pivo')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                    <p class = "project-footer-subtitle">Focus Planet</p>
                        <p class = "project-footer-title">Building a Product to Starting a Startup</p>
                        <button class = "read-more-button" onClick={() => navigate('/focusplanet')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
  );

}

export default GenSys;